import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { PPVContentService } from '@app/shared/utils/ppvcontent.service';
import {IContentCatalogViewModel} from '@core/models/interfaces/i-content-catalog-view-model';

@Component({
  selector: 'app-slider-book-item',
  templateUrl: './slider-book-item.component.html',
  styleUrls: ['./slider-book-item.component.scss']
})
export class SliderBookItemComponent implements OnInit {
  srcImage: string;
  srcFallback = 'assets/img/no-image.png';

  // LINE-CLAMPING https://css-tricks.com/line-clampin/#article-header-id-0
  lineClamp = {
    'display': '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  }

  @Input() book: IContentCatalogViewModel;
  @Input() myLibrary: boolean = true;
  @Input() mySocial: boolean = false;
  @Input() popupText: string;
  @Input() isWebview: boolean = false;
  @Output() onClickCaratuta: EventEmitter<Event> = new EventEmitter();
  @Output() unfollowEbook: EventEmitter<Event> = new EventEmitter();

  hovered = false;

  constructor(private ppvContentService: PPVContentService) {
  }

  ngOnInit() {
  }

  getImage() {
    return !!this.book && (this.book.urlBackgroundImage || this.book.urlImage || this.book.thumbnailUrl);
  }

  getPrice(): string {
    if(!this.ppvContentService.isPPV(this.book)) {
      return 'gratis';
    }
    return '$ ' + this.ppvContentService.getPrice(this.book);
  }
}
