import { ColorBreadCrumb } from '@enums/type-color-breadcrumb.enum';

export enum TypeContent {
  PROGRAM = 'Programas',
  PRAY = 'Oraciones',
  BIBLE = 'Biblia',
  ARTICLE = 'ARTÍCULOS',
  PODCAST = 'Podcasts',
  MINISTERY = 'Ministerios',
  PEOPLE = 'Personas',
  FAVORITES = 'Favoritos',
  LIBRARY = 'Librería',
  EBOOKS = 'Libros Digitales',
  AUDIOBOOKS = 'Audiolibros',
  MY_LIST = 'Mi lista',
  MY_FOLLOW = 'Seguidos',
  TRANSACTIONS = 'Transacciones',
  MY_CONTENT = 'Mi contenido',
  MY_LIBRARY = 'Mi librería',
  MY_CONFIGURATION = 'Configuración',
  CATALOG = 'Catálogo',
  DONATION = 'Donar',
  LIVE = 'En vivo',
  SEARCH = 'Búsqueda general',
  COMUNITY = 'Comunidad'
}

export enum TypeContentCover {
  PROGRAM = 'program',
  ARTICLE = 'article',
  PODCAST = 'podcast',
  PODCAST_ITEM = 'podcastitem',
  VIDEO = 'video',
  PERSON = 'person',
  ORGANIZATION = 'Organization'
}

export const CONTENT_PPV = {
  video: {title: 'VIDEO', color: ColorBreadCrumb.PURPLE},
  program: {title: 'PROGRAMA',  color: ColorBreadCrumb.PURPLE},
  ebook: {title: 'LIBRO DIGITAL',  color: ColorBreadCrumb.BLUE},
  audiobook: {title: 'AUDIOLIBRO',  color: ColorBreadCrumb.BLUE},
}
