<div class="header-button-container">
  <ng-container *ngIf="contentPPV && !contentPPV?.isParentPpv">
    <div *ngIf="contentPPV.rentalPrice" class="header-button">
      <ng-container *ngTemplateOutlet="buttonRent"></ng-container>
    </div>
    <div *ngIf="contentPPV.purchasePrice" class="header-button">
      <ng-container *ngTemplateOutlet="buttonPurcharse"></ng-container>
    </div>
    <div *ngIf="contentDetail.accessCodeEnable">
      <app-gift-code
        [contentDetail]="contentDetail"
        [contentType]="contentType">
      </app-gift-code>
    </div>
  </ng-container>
  <ng-container *ngIf="!contentPPV">
    <div *ngIf="contentDetail?.acquired" class="header-button">
      <ng-container *ngTemplateOutlet="buttonPlay"></ng-container>
    </div>
    <div *ngIf="!contentDetail?.acquired" class="header-button">
      <ng-container *ngTemplateOutlet="buttonFree"></ng-container>
    </div>
  </ng-container>
  <div *ngIf="contentDetail?.percentage" class="header-button-progress">
    <app-tag-progress-bar
      [progress]="contentDetail.percentage"
      [className]="'progressEbook'">
    </app-tag-progress-bar>
  </div>
</div>


<!-- template ButtonRent -->
<ng-template #buttonRent>
  <app-button-enlace-plus  [className]="'btn-book-rent'" (actionButton)="rent.emit()">
    <div textButton>
      ALQUILAR <span style="font-weight: 300">POR</span> ${{ contentPPV.rentalPrice }}
    </div>
  </app-button-enlace-plus>
</ng-template>

<!-- templete ButtonPurcharse -->
<ng-template #buttonPurcharse>
  <app-button-enlace-plus  [className]="'btn-book-purchase'" (actionButton)="purcharse.emit()">
    <div textButton class="button-text">
      COMPRAR <span style="font-weight: 300">POR</span> ${{ contentPPV.purchasePrice }}
    </div>
  </app-button-enlace-plus>
</ng-template>

<!-- templete ButtonPlay -->
<ng-template #buttonPlay>
  <app-button-enlace-plus  [className]="'btn-book-play'" (actionButton)="redirectToContent.emit()">
    <i iconButton class="iconlace iconlace-read-lineal"></i>
    <div textButton> LEER</div>
  </app-button-enlace-plus>
</ng-template>

<!-- templete ButtonFree -->
<ng-template #buttonFree>
  <app-button-enlace-plus  [className]="'btn-book-free'" (actionButton)="redirectToFree.emit()">
    <i iconButton class="iconlace iconlace-read-lineal"></i>
    <div textButton> GRATIS</div>
  </app-button-enlace-plus>
</ng-template>
