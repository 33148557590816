import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentDetail, ContentPpv } from '@app/core/models/interfaces/content-detail';

@Component({
  selector: 'app-header-more-info',
  templateUrl: './header-more-info.component.html',
  styleUrls: ['./header-more-info.component.scss']
})
export class HeaderMoreInfoComponent implements OnInit {

  @Input() contentDetail: ContentDetail;
  @Input() contentType: string;
  @Input() onlyInfo: boolean;
  @Output() rent: EventEmitter<void> = new EventEmitter();
  @Output() purcharse: EventEmitter<void> = new EventEmitter();
  @Output() redirectToContent: EventEmitter<void> = new EventEmitter();
  @Output() redirectToFree: EventEmitter<void> = new EventEmitter();

  className: string = '';
  imageUrl = '';
  defaultImage = 'assets/img/no-image-new.png'

  ngOnInit(): void {
   this.className = `content-detail-${this.contentType}`;
   this.imageUrl = this.contentDetail?.urlImage || this.contentDetail?.imageUrl || this.defaultImage;
   this.loadImage(this.imageUrl);
  }

  get contentPPV(): ContentPpv {
    return this.contentDetail?.contentPpv;
  }

  setDefaultImage() {
    this.imageUrl = this.defaultImage;
  }

  loadImage(url: string) {
    const img = new Image();
    img.src = url;
    img.onerror = () => {
      this.setDefaultImage();
    };
  }

}
