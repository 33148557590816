<div class="header-enlace" [ngClass]="{'animation': headerAnimation, 'hidden': isHiddenHeader}">
  <!-- LOGO -->
  <div class="header-logo">
    <a class="navbar-brand" (click)="redirectHome()">
      <img [src]="imgLogo" alt="Logo Enlace+" />
    </a>
  </div>
  <!-- TODOS LOS ITEMS -->
  <div class="header-items" [class.mobile]="isMobileSize">
    <!-- SE MOSTRARAN ALGUNOS ITEMS DEPENDIENDO DE LAS RUTAS O DISPOSITIVO -->
    <ng-container *ngIf="!isMobileSize">
      <div class="header-link text" [ngClass]="{ 'active': activeTab === routes.LIVE }"  (click)="redirectRoute(routes.LIVE)">
        En vivo
        <!-- Mockup realizado -->
        <div
          *ngIf="!hiddenThings"
          class="link"
          (click)="openOption(actions.live)"
          >
          En vivo
          <div class="header-icon">
            <i class="iconlace iconlace-chevron-down"></i>
          </div>
        </div>
        <ng-container *ngIf="isActionActive(actions.live)">
          <ng-container *ngTemplateOutlet="optionLink"></ng-container>
        </ng-container>
        <!-- Fin mockup -->
      </div>
      <div class="header-link text" [ngClass]="{ 'active': isProgramRoute }" (click)="redirectRoute(routes.SEARCH_V2, { format: 'program' })">
        Programas
      </div>
      <div class="header-link text"  [ngClass]="{ 'active': activeTab === routes.BIBLE }"
      *appFeatureIsOn="'WebEnableBible'"
      (click)="redirectRoute(routes.BIBLE)">
        Biblia
      </div>
      <div *ngIf="isComunnityEnabled" class="header-link text" [ngClass]="{ 'active': activeTab === routes.COMUNITY }"
        (click)="redirectRoute(routes.COMUNITY)">
        Comunidad
      </div>
      <div class="header-link text"  [ngClass]="{ 'active': activeTab === routes.LIBRARY }"
        *appFeatureIsOn="'WebEnableLibrary'"
        (click)="redirectRoute(routes.LIBRARY)">
        Librería
      </div>
      <div class="header-link text"  [ngClass]="{ 'active': activeTab === routes.ARTICLE || activeTab === routes.DETAIL_ARTICLE }" (click)="redirectRoute(routes.ARTICLE)">
        Artículos
      </div>
    </ng-container>
    <div class="header-link text more"  [ngClass]="{ 'active': isMoreTabActive(activeTab) }">
      <div class="link" (click)="openOption(actions.more)">
        Más
        <div class="header-icon">
          <i class="iconlace iconlace-chevron-down"></i>
        </div>
      </div>
      <ng-container *ngIf="isActionActive(actions.more)">
        <ng-container *ngTemplateOutlet="optionLink"></ng-container>
      </ng-container>
    </div>
    <div class="header-link text icon" *ngIf="NewSearchEnable" [ngClass]="{ 'active': activeTab === routes.SEARCH_V2 }">
      <i
        class="iconlace iconlace-search"
        (click)="redirectRoute(routes.SEARCH_V2)">
      </i>
    </div>
    <div class="header-link icon" *ngIf="!isSearchRoute && !NewSearchEnable">
      <i
        class="iconlace iconlace-search"
        (click)="openOption(actions.search)">
      </i>
      <ng-container *ngIf="isActionActive(actions.search)">
        <ng-container *ngTemplateOutlet="searchV2"></ng-container>
      </ng-container>
    </div>
    <div class="header-link donate" (click)="donate()">
      <div class="header-donate">DONAR</div>
    </div>
    <div
      *ngIf="!isUserLoggedIn"
      class="header-link icon user"
      (click)="showLoginMessage('enjoy-content')">
      <i class="iconlace iconlace-user icon-dimensions"></i>
    </div>
    <div *ngIf="isUserLoggedIn" class="header-link icon user">
      <div class="link" (click)="openOption(actions.user)">
        <img *ngIf="profileImage !== ''" [src-fallback]="imgUserDefault" [src]="profileImage" />
        <i *ngIf="profileImage == ''" class="iconlace iconlace-user icon-dimensions"></i>
      </div>
      <ng-container *ngIf="isActionActive(actions.user)">
        <ng-container *ngTemplateOutlet="optionLink"></ng-container>
      </ng-container>
    </div>
    <!-- SOLO VERSION MOBILE  -->
    <div class="header-link icon menu">
      <i class="iconlace iconlace-menu" (click)="openOption(actions.user)"></i>
      <ng-container *ngIf="isActionActive(actions.user)">
        <ng-container *ngTemplateOutlet="optionLink"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<!-- POPUP QUE MUESTRA DIFERENTES OPCIONES DESPLEGABLES -->
<ng-template #optionLink>
  <app-header-link
    [user]="userInfo"
    [active]="openLinkOption"
    [activeTab]="activeTab"
    [typeClass]="actionActive"
    [isMobile]="isMobileSize"
    [isSmallTablet]="isTabletSize"
    [ageRestriction]="isOlder13"
    [countries]="countries"
    [countrySelected]="countrySelected"
    [isComunnityEnabled]="isComunnityEnabled"
    (emitOption)="handleOption($event)"
    (emitRoutes)="redirectRouteWithParams($event)"
    (emitCountry)="changeCountry($event)">
  </app-header-link>
</ng-template>

<!-- NEW SEARCH V2 -->
<ng-template #searchV2>
  <app-search-v2
    [active]="isOpenPopupSearch"
    (emitOption)="handleOption($event)">
  </app-search-v2>
</ng-template>

<!-- MODALES: QUIENES SOMOS - CONTACTOS - OFICINAS -->
<app-modal
  [width]="isMobileSize ? '320' : '520'"
  [contentClass]="'show-info'"
  [showButtons]="false"
  [isActive]="openContact"
  (closeModal)="toggle(true)">
  <ng-container content>
    <ng-container *ngTemplateOutlet="infoEnlace"></ng-container>
  </ng-container>
</app-modal>

<app-modal
  [width]="isMobileSize ? '320' : '520'"
  [contentClass]="'show-info'"
  [showButtons]="false"
  [isActive]="openWhoAre"
  (closeModal)="toggle(false)">
  <ng-container content>
    <ng-container *ngTemplateOutlet="infoWho"></ng-container>
  </ng-container>
</app-modal>

<ng-template #infoEnlace>
  <div class="content-modal">
    <div class="content-title">Contacto</div>
    <div class="content-block">
      <div class="block-left">
        <i class="iconlace iconlace-callworld bigger"></i>
        <div class="content-text">
          <a href="http://call.enlace.org/" target="_blank">
            <div class="big-text">Llamada</div>
            <div class="small-text">por internet</div>
          </a>
        </div>
      </div>
      <div class="block-right cursor" (click)="fetchOffices()">
        <i class="iconlace iconlace-apartment bigger"></i>
        <div class="content-text">
          <div class="big-text">Oficinas</div>
          <div class="small-text">Internacionales</div>
        </div>
      </div>
    </div>
    <div class="content-block">
      <div class="block-left">
        <div class="content-text">
          <div class="big-text">Costa Rica</div>
          <div class="small-text">Oficina Principal</div>
        </div>
      </div>
      <div class="block-right mt-5">
        <div class="content-text">
          <div class="small-text mt-5">
            <i class="iconlace iconlace-phone"></i>
            <a href="tel:+1234567890?call">
              <span>(506) 2220-3323</span>
            </a>
          </div>
          <div class="small-text mt-5">
            <i class="iconlace iconlace-mail"></i>
            <a href="mailto:costarica@enlace.org">
              <span>costarica@enlace.org</span>
            </a>
          </div>
          <div class="small-text mt-5">
            <i class="iconlace iconlace-map-marker"></i>
            <a href="https://goo.gl/maps/piUifdGZsihXTFmK9" target="_blank">
            <span>600 mts oeste de la embajada americana</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #infoWho>
  <div class="content-modal">
    <div class="content-title">¿Quiénes somos?</div>
    <div class="content-block">
      <div class="block-left">
        <div class="content-text">
          <div class="small-text">
            Enlace+ es la plataforma donde te proveemos las herramientas para tu
            crecimiento espiritual. Aquí encontrarás contenido que edificará tu
            vida y te acompañará en tu caminar diario con Dios.
          </div>
          <br />
          <div class="small-text">
            Después de 30 años de llevar el mensaje sublime de la Cruz a través
            de la televisión, en el 2018 lanzamos Enlace+ con el deseo de estar
            presente en los medios de comunicación del momento. Como siempre,
            Enlace sigue y seguirá siendo, una imagen de lo alto.
          </div>
          <img class="content-img" src="assets/img/img-plus-glass.png" />
        </div>
      </div>
    </div>
  </div>
</ng-template>
