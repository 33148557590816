import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class PopupService {

  activePopups = {
    fontSize: false,
    share: false,
    follow: false,
    setting: false,
    audioTrack: false,
    quality: false,
    caption: false
  }

  constructor() { }

  // Método para obtener el estado de un popup específico
  getPopupState(key: string): boolean {
    return this.activePopups[key] || false;
  }

  getActivePopup(): string | null {
    for (const key in this.activePopups) {
      if (this.activePopups[key]) {
        return key;
      }
    }
    return null;
  }


  // Método para alternar el estado de un popup
  togglePopup(key: string): void {
    Object.keys(this.activePopups).forEach((popupKey) => {
      if (popupKey !== key) {
        this.activePopups[popupKey] = false;
      }
    });
    this.activePopups[key] = !this.activePopups[key];
  }

}
