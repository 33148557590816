<div class="checkout-modal" *ngIf="paymentResult == null && this.userCards.length > 0">
  <div class="content">
    <i (click)="activeModal.dismiss()" class="iconlace iconlace-cross close-btn"></i>

    <div class="row">
      <div class="col-xs-12 col-md-6">
        <div class="content-image">
          <!--BooksImage es para Audiobooks y Ebooks debido a que utilizan otra resolucion-->
          <!--DefaultImage es para todos los que no cumplen el requisito del de arriba y el titulo no utiliza salto de linea-->
          <!--DefaultImageTitleDoubleLine es para todos los que no cumplen el requisito del 1ro y el titulo utiliza salto de linea-->
          <img [ngClass]="{
          'booksImage': paymentContent.contentType === 'audiobook' || paymentContent.contentType === 'ebook',
          'defaultImage': paymentContent.contentType !== 'audiobook' && paymentContent.contentType !== 'ebook' && paymentContent.title.length <= 27,
          'defaultImageTitleDoubleLine': paymentContent.contentType !== 'audiobook' && paymentContent.contentType !== 'ebook' && paymentContent.title.length > 27}"
          [src]="paymentContent?.imageContent || '/assets/img/no-image.png'">
        </div>
      </div>

      <div class=" col-xs-12 col-md-6">
        <div class="payment">
          <div class="content-title">
            <h3>{{ paymentContent.title }}</h3>
            <label>{{ paymentContent.isRent ? 'Alquiler por ' + paymentContent?.rentalDays + ' días' : 'Compra' }}</label>
          </div>
          <div *ngIf="previewTax" class="info-payment">
            <div class="info-item">
              <div class="info-title">Subtotal:</div>
              <div class="info-subtitle">${{previewTax.totalAmount | dotSeparator:'EN' }}</div>
            </div>
            <div class="info-item">
              <div class="info-title">Impuesto de ventas:</div>
              <div class="info-subtitle">${{previewTax?.totalTaxCalculated | dotSeparator:'EN' }}</div>
            </div>
            <div class="info-item border-bottom">
              <div class="info-title">Total:</div>
              <div class="info-subtitle">${{(previewTax.totalAmount + previewTax.totalTaxCalculated) | dotSeparator:'EN' }}
              </div>
            </div>
            <div class="info-help">(Dólares estadounidenses)</div>
          </div>
        </div>
      </div>

      <div class="col-xs-12 cards-list">
        <div class="row payment-method">
          <div class="info-title">Método de pago</div>
          <div class="card-table">
            <div *ngFor="let card of cards" class="card" [ngClass]="{'expired': card.expired}" (click)="selectCard(card)">
              <div class="text-card name">
                <div class="containerName">
                  <i class="iconlace iconlace-check-status" [ngClass]="{'active-check': (selectedSavedCard?.cardId == card.cardId && !card.expired),'expired': card.expired}"></i>
                  <span class="creditName" *ngIf="showCreditCard " [ngClass]="{'expired': card.expired}">{{ card.textCard }}</span>
                  <span class="creditName" *ngIf="!showCreditCard" [ngClass]="{'expired': card.expired}">{{ card.textCardPlane }}</span>
                  <span *ngIf="card.expired" class="expired-card" placement="top" [ngbTooltip]="tooltipText">VENCIDA</span>
                </div>
              </div>

              <div class="text-card holderName" *ngIf="showHolderName">
                <span class="text-card"  [ngClass]="{'expired': card.expired}">{{ card.holderName }}</span>
              </div>

              <div class="text-card date" *ngIf="showDate && card.expired">
                <span class="text-card"  [ngClass]="{'expired': card.expired}">{{ card.date }}</span>
              </div>
              <div class="text-card date" *ngIf="!card.expired">
                <span class="text-card"  [ngClass]="{'expired': card.expired}">{{ card.date }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="selectedSavedCard?.isLocal" class="doc-section">
      <form #form="ngForm" novalidate autocomplete="off">
        <div class="doc-number">
          <span class="doc-text">Número de identificación:</span>
          <input #paymentCardDocument="ngModel" appOnlyNumber [(ngModel)]="docId" #docIdInput name="docId" id="docId" type="text" maxlength="20" class="form-control" placeholder="Ingresa tu número de documento"
          (ngModelChange)="clearError()"
          [class.has-error]="(paymentCardDocument.invalid && paymentCardDocument.touched) || (paymentCardDocument.untouched && paymentCardDocument.pristine && paymentCardDocument.invalid && form.submitted) || errorMsg"
          [ngClass]="{
          'gray-border': paymentCardDocument.valid && paymentCardDocument.touched || (paymentCardDocument.pristine && !form.submitted),
          'orange-border': !(paymentCardDocument.valid || (paymentCardDocument.pristine && !form.submitted)) }"
          required/>
          <div class="error-message">
            <span [hidden]="!(paymentCardDocument.valid && paymentCardDocument.touched && errorMsg)">{{errorMsg}}</span>
            <span [hidden]="!(paymentCardDocument.invalid && paymentCardDocument.touched) || (paymentCardDocument.untouched && paymentCardDocument.pristine && paymentCardDocument.invalid && form.submitted)">La cédula física o jurídica es requerida.</span>
          </div>
        </div>
      </form>
    </div>
    <div class="doc-section">
      <form action="">
        <div class="doc-error">
          <div class="error-message">
            <span [hidden]="!selectedCardExpired">{{errorMsg}}<span class="expired-card" placement="top" [ngbTooltip]="tooltipText">VENCIDA</span></span>
          </div>
        </div>
      </form>
    </div>
    <div class="pay">
      <button class="btn" [disabled]="thereIsError" [ngClass]="{disabled: thereIsError}" (click)="pay()">Pagar</button>
    </div>

    <div class="other-pay">
      <a (click)="goToOtherPayment()">Usar otro medio de pago</a>
    </div>


  </div>
</div>
