<div id="modal-prayer">
  <div class="modal-header">
    <button type="button" class="close closemodal-login" (click)="activeModal.dismiss()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body">
    <div class="thumbnail thumbnail-prayer ">
      <div class="caption">
        <p class="category">{{title}}</p>
        <div class="col-md-2">
          <img *ngIf="!isSuccesful" class="img-responsive" src="../../../../assets/img/icons/exclamation-mark.png" />
          <img *ngIf="isSuccesful" class="img-responsive" src="../../../../assets/img/icons/checked.png"/>
        </div>
        <div class="txt-group col-md-10">
          <p class="txt" [innerHTML]="text"></p>
        </div>
        <div class="form-group center">
          <a (click)="activeModal.dismiss()" class="btn btn-primary">Aceptar</a>
        </div>
      </div>
    </div>
  </div>
</div>



