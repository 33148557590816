import { AfterViewInit, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrayRequestService } from '@app/api-client/v2/pray-request.service';
import { IUser } from '@app/core/models/interfaces/i-user.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidateExpressionService } from '@app/shared/services/lib/validate-expression.service';
import { AnimationOptions } from 'ngx-lottie';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';

enum PrayResult {
  Default = 'default',
  Success = 'success',
  Error = 'error',
}

@Component({
  selector: 'app-pray-request-modal',
  templateUrl: './pray-request-modal.component.html',
  styleUrls: ['./pray-request-modal.component.scss']
})
export class PrayRequestModalComponent implements OnInit, AfterViewInit {

  @Input() user: IUser;
  public form: FormGroup;
  PrayResult = PrayResult;
  result: PrayResult = PrayResult.Default;
  invalidText: boolean;

  options: AnimationOptions = {
    path: '/assets/json/lottie-manitas-oración.json',
    loop: false
  };

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public prayRequest: PrayRequestService,
    private renderer: Renderer2,
    public validateExpression: ValidateExpressionService,
    private loadingOverlayService: LoadingOverlayService
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  ngAfterViewInit(): void {
    this.setElementClass('.modal-pray-request2', 'step-one');
  }

  setElementClass(findElement: string, typeClass: string): void {
    const element = document.querySelector(findElement);
    if (element) {
      this.renderer.addClass(element, typeClass);
    }
  }

  removeElementClass(findElement: string, typeClass: string): void {
    const element = document.querySelector(findElement);
    if (element) {
      this.renderer.removeClass(element, typeClass);
    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  sendRequest() {
    this.loadingOverlayService.showLoadingOverlay();
    const uid = this.user && this.user.id;
    const peticion = this.descriptionField.value;

    this.validateExpression.getValidateExpression(peticion).subscribe(result => {
      if (!result.value) {
        this.prayRequest.prayRequest(uid, peticion).subscribe(result => {
          this.showSuccessMessage();
        }, error => {
          this.showErrorMessage();
        });
      }
      else {
        this.loadingOverlayService.hideLoadingOverlay();
        this.descriptionField.setErrors({ invalid: true });
      }
    },
      error => { this.showErrorMessage(); });


  }

  get descriptionField(): AbstractControl {
    return this.form.get('description');
  }

  get profileImage(): string {
    if (!!this.user && this.user.imageAvatar && this.user.imageAvatar.url) return this.user.imageAvatar.url;
    return '';
  }

  showSuccessMessage() {
    this.loadingOverlayService.hideLoadingOverlay();
    this.result = PrayResult.Success;
    this.removeElementClass('.modal-pray-request2', 'step-one');
  }

  showErrorMessage() {
    this.loadingOverlayService.hideLoadingOverlay();
    this.result = PrayResult.Error;
    this.removeElementClass('.modal-pray-request2', 'step-one');
  }

  public isInValid(control: AbstractControl): boolean {
    if (control.value.length > 3) {
      this.invalidText = control.touched && !!control.errors;
      return control.touched && !!control.errors;
    }
    this.invalidText = false;
    return false;
  }

  public hasError(type: string, control: string): boolean {
    return !!this.form.get(control).errors && this.form.get(control).errors[type];
  }

  public closeModal(): void {
    this.activeModal.close();
  }

}
