<div class="{{clazz}} item">
  <!--Primera Fase-->
  <div class="{{clazz}}-container item-container">
    <!--Segunda Fase-->
    <a (click)="redirectArticle(routerLink2)" class="clickAll">Leer más ></a>
    <figure>
      <img *ngIf="showImage && image || typeCaller == 4 && image" [src]="image" alt=""
           src-fallback="/assets/img/no-image.png"/></figure>
    <div class="{{clazz}}-content">
      <!--Tercera Fase-->
      <!--Condition 1-->
      <div class="{{clazz}}-person" *ngIf="showImageAuthor && imageAuthor !== ''">
        <img [src]="imageAuthor" alt="" />
      </div>
      <!--Condition 2-->
      <div class="{{clazz}}-person" *ngIf="showImageAuthor && imageAuthor === ''">
        <img src="assets/img/icons/user.svg" alt=""/>
      </div>
      <!--Condition 3-->
      <div class="{{clazz}}-info others" *ngIf="clazz !== 'horizontal-relative-item' &&
                  clazz !== 'horizontal-relative-item-v2' &&
                  clazz !== 'horizontal-banner-item' &&
                  clazz !== 'horizontal-interview-item' &&
                  clazz !== 'horizontal-new-item' &&
                  clazz !== 'horizontal-ArticleType-item'">
        <span *ngIf="date">{{date}}</span>
        <span *ngIf="!date">{{dateCreation}}</span>
        <h2>{{title | cuttext: 30}}</h2>
        <p *ngIf="showAuthor">{{author}}</p>
      </div>
      <!--Condition 4-->
      <div class="{{clazz}}-info" *ngIf="clazz === 'horizontal-relative-item'">
        <!--p>Armando Paredes <span>|</span> <em>Apóstol</em></p-->
        <span *ngIf="date">{{date}}</span>
        <span *ngIf="!date">{{dateCreation}}</span>
        <h2>{{title | cuttext: 60}}</h2>    
        <p input [ngClass]="{'ltext': charlen > 18}" *ngIf="showAuthor">{{author | cuttext: 20}}</p>
        <a [routerLink]="routerLink2" class="btn btn-info btn-sm">Leer más</a>
      </div>
      <!--Condition 5-->
      <div class="{{clazz}}-info" *ngIf="clazz === 'horizontal-relative-item-v2'">
        <span *ngIf="date">{{date}}</span>
        <span *ngIf="!date">{{dateCreation}}</span>
        <p>{{description | cuttext: 250}}</p>
        <a [routerLink]="routerLink2" class="btn btn-link read arrow btn-sm">Leer más</a>
      </div>
      <!--Condition 6-->
      <div class="{{clazz}}-info" *ngIf="clazz === 'horizontal-banner-item'">
        <!-- <span>05 JUL 2018</span> -->
        <h2 *ngIf="showAuthor">{{author}}</h2>
        <p>{{description}}</p>
        <a class="btn
         btn-info btn-sm" [routerLink]="routerLink2">Leer más</a>
      </div>
      <!--Condition 7-->
      <div class="{{clazz}}-info" *ngIf="clazz === 'horizontal-interview-item'">
        <h2 *ngIf="showAuthor">{{author}}
          <div class="blue" *ngIf="type">|</div>
          <small>{{type | translate}}</small></h2>
        <span *ngIf="date">{{date}}</span>
        <span *ngIf="!date">{{dateCreation}}</span>
        <p>{{title}}</p>
        <a class="btn btn-link read arrow btn-sm" [routerLink]="routerLink2">Leer más</a>
      </div>
      <!--Condition 8-->
      <div class="{{clazz}}-info" *ngIf="clazz === 'horizontal-new-item'">
        <span *ngIf="date">{{date}}</span>
        <span *ngIf="!date">{{dateCreation}}</span>
        <p>{{title}}</p>
      </div>
      <!--Condition 9-->
      <div class="{{clazz}}-info" *ngIf="clazz === 'horizontal-ArticleType-item'">
        <span *ngIf="date">{{date}}</span>
        <span *ngIf="!date">{{dateCreation}}</span>
        <p>{{title | cuttext: 30}}</p>
      </div>
    </div>
  </div>
</div>
