<ng-container *ngIf="type !== 'article'">
  <ng-container *ngIf="isContentVideo; else otherContent">
    <ng-container *ngTemplateOutlet="videoItem"></ng-container>
  </ng-container>

  <ng-template #otherContent>
    <ng-container *ngIf="isEbook">
      <app-slider-book-item [book]="video" [myLibrary]="false" [isWebview]="isWebview" (onClickCaratuta)="navigate()"></app-slider-book-item>
    </ng-container>
    <ng-container *ngIf="isAudiobook">
      <app-slider-audiobook-item [audiobook]="video" [myLibrary]="false" [isWebview]="isWebview" (onClickCaratuta)="navigate()"></app-slider-audiobook-item>
    </ng-container>
    <ng-container *ngIf="isBoth">
      <ng-container *ngTemplateOutlet="organizationItem"></ng-container>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #videoItem>
  <li class="col-xs-6 col-sm-4 col-lg-5" [ngClass]="isWebview == false ? 'program-item' : 'program-item-webview'">
    <div class="program-item-image" [ngClass]="'program-item-image-' + type">
      <app-ppv-label-indicator [content]="video"></app-ppv-label-indicator>
      <app-ppv-remaining-indicator [content]="video"></app-ppv-remaining-indicator>
      <img src="{{ imageUrl || 'assets/img/no-image.png' }}" src-fallback="/assets/img/no-image.png" />
      <ng-container *ngIf="hasPercent">
        <app-video-progress-bar style="left: 0;bottom: 0;position: absolute;width: 100%;z-index: 4;" #progress
          [percent]="percent">
        </app-video-progress-bar>
      </ng-container>
    </div>
    <div class="program-item-overlay" [ngClass]="'program-item-overlay-' + type">
      <app-ppv-remaining-indicator [content]="video"></app-ppv-remaining-indicator>
      <a class="program-item-preview" (click)="navigate()">
        <div class="info-detail">
          <div class="info-detail-play" *ngIf="!isProgram">
            <img src="assets/img/icons/play_thumb.svg" ngbTooltip="{{ 'watch-now' | translate }}" placement="top"
              container="body" *ngIf="!isWebview"/>
          </div>
          <div class="info-detail-text" [ngClass]="type">
            <div class="info-detail-text_title" *ngIf="video.topTitle && !isWebview" [ngClass]="type">
              {{ video.topTitle }}
            </div>
            <div class="info-detail-text_description" *ngIf="!isWebview">
              {{ video.title }}
            </div>
            <p class="info-detail-text_timestamp" *ngIf="video.duration && !isWebview">
              {{ video.duration | timeHumanFormat }}
            </p>
          </div>
        </div>
      </a>
    </div>
  </li>
</ng-template>

<ng-template #organizationItem>
  <li class="col-xs-6 col-sm-4 col-md-2" [ngClass]="isWebview == false ? 'program-item' : 'program-item-webview'">
    <div class="organization">
      <div class="program-item-image" [ngClass]="'program-item-image-' + type">
        <app-ppv-label-indicator [content]="video"></app-ppv-label-indicator>
        <app-ppv-remaining-indicator [content]="video" [isCatalog]="true"></app-ppv-remaining-indicator>
        <img src="{{ imageUrl || 'assets/img/no-image.png' }}" src-fallback="/assets/img/no-image.png" alt="" />
      </div>
      <div class="program-item-overlay" [ngClass]="'program-item-overlay-' + type">
        <app-ppv-remaining-indicator [content]="video" [isCatalog]="true"></app-ppv-remaining-indicator>
        <a class="program-item-preview" (click)="navigate()">
          <div class="program-item-preview-container">
            <div class="program-item-preview-container-content">
              <p>
                <strong *ngIf="!isWebview">{{ titleLbl ? titleLbl : video.title }}</strong>
              </p>
              <p *ngIf="subtitle && !isWebview">{{ subtitle }}</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </li>
</ng-template>

<!-- Bloque agregado para Categorias de Articulos -->
<li class="col-xs-6 col-sm-4 col-md-2 program-item list-group-item" style="width: inherit; padding: 0px; border: hidden"
  *ngIf="type === 'article' && typeCaller == 3">
  <div>
    <app-article-item [date]="video.publishedDate | dateformat: 'dd MMM yyyy'"
      [dateCreation]="video.createdDate | dateformat: 'dd MMM yyyy'" [routerLink2]="['/', video.type, video.contentId]"
      [clazz]="article ? article.clazzItem : 'horizontal-banner-item'"
      [showImageAuthor]="article ? article.showImageAuthor : ''" [showImage]="article ? article.showImage : ''"
      [showAuthor]="article ? article.showAuthor : ''" [title]="video.title" [description]="video.description"
      [image]="video.urlImage" [author]="
        video.hostsList && video.hostsList.length > 0
          ? video.hostsList[0].fullName
          : ''
      " [type]="video.type" [imageAuthor]="
        video.hostsList && video.hostsList.length > 0
          ? video.hostsList[0]?.image
          : ''
      " [typeCaller]="typeCaller">
    </app-article-item>
  </div>
</li>

<!-- Bloque agregado para Articulos por Tipo -->
<div class="article-item" style="width: inherit; padding: 0px; border: hidden"
  *ngIf="type === 'article' && typeCaller == 4">
  <app-content-item [imageUrl]="video.urlImage || video.urlBackgroundImage" [title]="video.title" [content]="video"
    [type]="video.type" [contentId]="video.contentId" [isFollow]="false"
    [subtitle]="video.hostsList | join: ', ' : 'fullName'" [isWebview]="isWebview">
  </app-content-item>
</div>
