<footer class="footer-main container-fluid"
 [ngClass]="{'absolute': isPositionAbsolute, 'sticky': isPositionSticky}" *ngIf="!isHiddenFooter">
  <div class="row simplefoot text-center">
    <div class="simplefoot-social">
      <ol class="social social-foot">
        <li>
          <a href="https://twitter.com/enlacetv" target="_blank" class="social-link btn-twitter">
            <i class="iconlace iconlace-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/enlacetv" target="_blank" class="social-link btn-facebook">
            <i class="iconlace iconlace-facebook"></i>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/enlacetv/" target="_blank" class="social-link btn-instagram">
            <i class="iconlace iconlace-instagram"></i>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/user/televisionenlace" target="_blank" class="social-link btn-youtube">
            <i class="iconlace iconlace-youtube"></i>
          </a>
        </li>

      </ol>
    </div>
    <div class="simplefoot-legacy">
      <div class="legacy-foot">
        <div class="privacyterms-foot">
          <a href="/privacy-policies" target="_blank" class="link-terms">Políticas de Privacidad</a>
          <span class="separator"></span>
          <p>•</p>
          <span class="separator"></span>
          <a  href="/terms-of-use" target="_blank" class="link-terms">Términos de uso</a>
          <span class="separator"></span>
          <p>•</p>
          <span class="separator"></span>
          <a  href="/faq" target="_blank" class="link-terms">Ayuda</a>
          <span class="separator"></span>
          <p>•</p>
          <span class="separator"></span>
          <a  href="mailto:soporte@enlace.plus" target="_blank" class="link-terms margin">Soporte</a>
        </div>
        <div class="copy-foot">
          <span class="separator"></span>
          <p>Copyright © {{currentYear}} Enlace es contenido cristiano.</p>
        </div>
      </div>
    </div>
    <div class="simplefoot-logo">
      <div class="desktop enlacelogo-foot">
        <div class="iso-foot">
          <img src="../../../../assets/img/icons/isotipo-enlace.png" alt="">
        </div>
        <div class="logo-foot">
          <img src="../../../../assets/img/logo-enlace-w.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</footer>

