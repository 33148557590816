import { Pipe, PipeTransform } from '@angular/core';
import { IHeroHome } from '@interfaces/i-hero-home';

@Pipe({
  name: 'heroImageHome'
})
export class HeroImageHomePipe implements PipeTransform {

  private _imageDefault = 'assets/img/header-slider/bg_slider.png';

  transform(hero: IHeroHome, isDesktop: boolean): string {
    const imageUrl = isDesktop ? hero?.imageWebUrl : hero?.imageMobileUrl;
    return imageUrl ?? this._imageDefault;
  }

}
