import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentDetail, ContentPpv } from '@app/core/models/interfaces/content-detail';


@Component({
  selector: 'app-header-ebook',
  templateUrl: './header-ebook.component.html',
  styleUrls: ['./header-ebook.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderEbookComponent  {

  @Input() contentDetail: ContentDetail;
  @Output() rent: EventEmitter<void> = new EventEmitter();
  @Output() purcharse: EventEmitter<void> = new EventEmitter();
  @Output() redirectToContent: EventEmitter<void> = new EventEmitter();
  @Output() redirectToFree: EventEmitter<void> = new EventEmitter();

  contentType: string = 'ebook';

  get contentPPV(): ContentPpv {
    return this.contentDetail?.contentPpv;
  }

}
