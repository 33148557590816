import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-base-page-layout',
  templateUrl: './base-page-layout.component.html',
  styleUrls: ['./base-page-layout.component.scss']
})
export class BasePageLayoutComponent {

  @Input() comunityState: boolean;
  @Input() clazz = '';
  // RETIRAR
  @Input() color = '#A700FF';
  @Input() titlelbl: string;
  @Input() titlelbl_LinkName: string;
  @Input() titlelbl2: string;
  @Input() titlelbl_LinkName2: string;
  @Input() titlelbl_CatName: string;
  //
  @Input() id: string;
  @Input() subtitle: string;
  @Input() fullwidth: boolean = true;

  @Input() classApply: string;
  @Input() webViewFixClass = '';

  @Output()
  scrolled = new EventEmitter<void>();

  @Output()
  clickOnBackToHome = new EventEmitter<void>();

  constructor() { }

  onScroll() {
    this.scrolled.emit();
  }

  clickOnBackToHome_1() {
    this.clickOnBackToHome.emit();
  }
}
