<app-skeleton-more-info *ngIf="loadingSkeleton" [loading]="loadingSkeleton" [contentType]="contentType"></app-skeleton-more-info>

<div *ngIf="!loadingSkeleton" class="content-detail" appClickOutsideModal (clickOutside)="closeModal(true)" >
  <div class="content-close">
    <button
      type="button"
      class="content-close-button"
      (click)="closeModal(true)"
      aria-label="Close">
      <span class="content-close-icon" aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-header-more-info
    [contentDetail]="contentDetail"
    [contentType]="contentType"
    [onlyInfo]="onlyInfo"
    (rent)="rentAction()"
    (purcharse)="acquireAction()"
    (redirectToContent)="redirectToContent()"
    (redirectToFree)="redirectToFree()">
  </app-header-more-info>
  <app-video-detail-modal
    *ngIf="contentType === 'video'"
    [videoDetail]="contentDetail"
    [contentType]="contentType"
    [onlyInfo]="onlyInfo"
    (checkModalExceedsViewport)="checkIfModalExceedsViewport()"
    (closeModal)="closeModal()"
    (rent)="rentAction()"
    (purcharse)="acquireAction()"
    (redirectToContent)="redirectToContent()"
    (programPurcharseAction)="purcharseProgram($event)"
    (programRentAction)="rentProgram($event)">
  </app-video-detail-modal>
  <app-ebook-detail-modal
    *ngIf="contentType === 'ebook'"
    [ebookDetail]="contentDetail"
    [contentType]="contentType"
    (checkModalExceedsViewport)="checkIfModalExceedsViewport()"
    (closeModal)="closeModal()"
    (rent)="rentAction()"
    (purcharse)="acquireAction()"
    (redirectToContent)="redirectToContent()"
    (redirectToFree)="redirectToFree()">
  </app-ebook-detail-modal>
  <app-program-detail-modal
    *ngIf="contentType === 'program'"
    [programDetail]="contentDetail"
    [contentType]="contentType"
    (closeModal)="closeModal()"
    (checkModalExceedsViewport)="checkIfModalExceedsViewport()"
    (rent)="rentAction()"
    (purcharse)="acquireAction()"
    (redirectToContent)="redirectToContent()">
  </app-program-detail-modal>
</div>
