import {
  OnInit, Component, HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OfficesModalComponent } from './offices-modal/offices-modal.component';

import { COUNTRIES_LIST } from '@constants/country-time-zone.constants';
import { IOfficesViewModel } from '@interfaces/i-offices-view-model';
import { CountryTimeZone } from '@interfaces/i-country';
import { IUser } from '@interfaces/i-user.interface';
import { TypePropertyLocalStorage } from '@enums/type-property-localstorage.enum';
import { ActionHeader, typeOptionHeader } from '@enums/type-header-option.enum';
import { RoutesApp } from '@enums/type-routes-app.enum';

import { FeatureFlagV3Service } from '@services/feature-flags-v3.service';
import { UserStateService } from '@services/user-state.service';
import { OrganizationService } from '@api/v2/organization.service';
import { SettingService } from '@api/v2/setting.service';
import { CatalogV2Service } from '@api/v2/catalog-v2.service';
import { CanRefreshTokenService } from '@utils/can-refresh-token.service';

import { LoginModalComponent } from '@layout/components/login-modal/login-modal.component';
import { AdvanceSearchService } from '@layout/components/search-advanced/services/advance-search.service';
import { environment as ENV } from '@environment/environment';
import { HomeCatalogStateService } from '@app/shared/services/home-catalog-state.service';
import { LogoutService } from '@app/shared/utils/logout.service';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @LocalStorage(TypePropertyLocalStorage.USER) private _user: IUser;
  @LocalStorage(TypePropertyLocalStorage.SUBSCRIPTION_FROM_LANDING) subscriptionFromLanding: boolean;
  @LocalStorage(TypePropertyLocalStorage.PREVIEW_URL) previewURL: string;
  @LocalStorage(TypePropertyLocalStorage.IS_FROM_AUTH) isFromAuth: boolean;

  public hiddenThings: boolean = true;
  public imgLogo: string = 'assets/img/logoEplus.svg';
  public imgUserDefault: string = 'assets/img/icons/user.svg';
  public headerAnimation: boolean = false;
  public openLinkOption: boolean = false;
  public actionActive: string = '';
  public actions = {
    live: typeOptionHeader.LIVE,
    more: typeOptionHeader.MORE,
    user: typeOptionHeader.USER,
    search: typeOptionHeader.SEARCH,
  };
  public routes = RoutesApp;
  public mobileRoutes: string[] = ['donate','suscribirme','payment','mobile', 'auth'];
  public isOlder13: boolean = false;
  public openContact: boolean = false;
  public openWhoAre: boolean = false;
  public countrySelected: CountryTimeZone;
  public countries = COUNTRIES_LIST;
  public offices: IOfficesViewModel[] = [];
  public activeTab = '';
  public moreTabActiveTitles: string[] = ['/podcast','/organizations','/organization','/people'];
  public isStandAlone: boolean;
  isMobileSize = false;
  isTabletSize = false;

  private scrollTopAnimation: number = 100;
  private region: string = 'world';
  private loginMessage: string;

  constructor(
    private router: Router,
    private sessionStorageService: LocalStorageService,
    private modalService: NgbModal,
    private canRefreshTokenService: CanRefreshTokenService,
    private advanceSearchService: AdvanceSearchService,
    private userService: UserStateService,
    private featureFlagService: FeatureFlagV3Service,
    private organizationService: OrganizationService,
    private settingsService: SettingService,
    private catalogV2Service: CatalogV2Service,
    private catalogState: HomeCatalogStateService,
    private logoutService: LogoutService,
    private loadingOverlayService: LoadingOverlayService
  ) {
    this.resetUrlRoute();
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll(
    event: Event
  ): void {
    const offset =
      window['pageYOffset'] ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.headerAnimation = offset >= this.scrollTopAnimation;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    this.canRefreshTokenService.clearKey();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    this.handleResize();
  }

  ngOnInit(): void {
    this.initConfig();
    this.handleResize();
  }

  get queryParams(): string {
    return this.router.routerState.snapshot.root.queryParams['fromMobile'];
  }

  get currentRoute(): string {
    return this.router.url.split('?')[0];
  }

  get isHiddenHeader(): boolean {
    const currentRoute = this.currentRoute;
    const queryParams = this.queryParams;

    // Agregar lógica adicional para ocultar el header en una ruta específica con parámetros
    return currentRoute.split('/').some((el) => this.mobileRoutes.includes(el))
      || (currentRoute === '/community' && queryParams === 'true');
  }

  get isSearchRoute(): boolean {
    return this.currentRoute === RoutesApp.SEARCH;
  }

  get isProgramRoute(): boolean {
    return this.activeTab === this.routes.PROGRAMS
      || this.activeTab === this.routes.PROGRAM
        || (this.activeTab === this.routes.VIDEO && !this.isStandAlone);
  }

  get profileImage(): string {
    if (!!this._user && this._user.imageAvatar && this._user.imageAvatar.url) return this._user.imageAvatar.url;
    return '';
  }

  get userInfo(): IUser {
    return this._user;
  }

  // Para B2C toca usar el authenticationService
  get isUserLoggedIn(): boolean {
    return this.userService.isUserLoggedIn;
  }

  get isOpenPopupSearch(): boolean {
    return this.advanceSearchService.isOpenSearch;
  }

  get isComunnityEnabled(): boolean {
    return this.featureFlagService.checkFeatureOn('WebEnableCommunity');
  }

  get isLandingDonationEnabled(): boolean {
    return this.featureFlagService.checkFeatureOn('WebEnableDonations');
  }

  get NewSearchEnable(): boolean {
    return this.featureFlagService.checkFeatureOn('NewSearchEnable');
  }

  handleResize(): void {
    const width = window.innerWidth;
    this.isMobileSize = width <= 1024;
    this.isTabletSize = width >= 768 && width <= 1024
  }

  public initConfig(): void {
    this.monitorUserChanges();
    this.selectCountryTimezone();
    this.setActiveTabOnReload();
  }

  public resetUrlRoute(): void {
    // Esta lógica esta afectando el performance - por el momento se mantendrá desactivado.
    // this.router.events.forEach((event) => {
    //   this.isStandAlone = this.activatedRoute.snapshot.queryParamMap.get('standalone') === 'true';
    //   //Se quita de la url el standalone para mantener homogenea la url
    //   const url = this.router.url.replace('?standalone=true','');
    //   this.location.replaceState(url);
    //   this.setActiveTabOnReload();
    // });
  }

  public isActionActive(action: string): boolean {
    return this.actionActive === action;
  }

  public monitorUserChanges(): void {
    this.changeUser();
    this.sessionStorageService
    .observe('user')
    .subscribe(() => this.changeUser());
  }

  public changeUser(): void {
    this.isOlder13 = this.userService.userIsOlderThan13();
  }

  public selectCountryTimezone(): void {
    this.countrySelected = COUNTRIES_LIST.find(x => x.value === this.region);
    this.settingsService.geolocationip().then((location: string) => {
      if (location && location.length > 0) {
        this.region = location;
        const currentCountry = COUNTRIES_LIST.find(x => x.value === this.region);
        this.countrySelected =  !!currentCountry ? currentCountry : COUNTRIES_LIST.find(x => x.value === 'world');
      }
    });
  }

  public setLoginCustomMessage(message: string) {
    this.loginMessage = message;
    this.advanceSearchService.isOpenSearch = false;
  }

  public openModalOffices(offices: IOfficesViewModel[]): void {
    this.toggle(true);
    const modalRef = this.modalService.open(OfficesModalComponent, {
      windowClass: 'modal-offices',
      backdropClass: 'light-black-backdrop'
    });
    modalRef.componentInstance.offices = offices;
  }

  public fetchOffices(): void {
    if (this.offices.length > 0)  {
      this.openModalOffices(this.offices);
    } else {
      this.organizationService.getOffices()
      .then((result: IOfficesViewModel[]) => {
        this.offices = result;
        this.openModalOffices(this.offices);
      });
    }
  }

  public showLoginMessage(message: string, textSize: string = 'big'): void {
    this.loginMessage = message;
    const modalRef = this.modalService.open(LoginModalComponent, {
      windowClass: 'dark-modal modal-outlogin',
      backdropClass: 'light-black-backdrop'
    });
    modalRef.componentInstance.customMessageTag = this.loginMessage;
    modalRef.componentInstance.textSize = textSize;
    this.advanceSearchService.isOpenSearch = false;
  }

  public donate(): void {
    if (this.isUserLoggedIn) {
      if (this._user && this._user.id) {
        const urlWithUserId = `${ENV.donationUrl}?uid=${this._user.id}`;
        window.open(urlWithUserId, "_blank");
      } else {
        window.open(ENV.donationUrl, "_blank");
      }
    } else {
      window.open(ENV.donationUrl, "_blank");
    }
  }

  public redirectHome(): void {
    this.activeTab = '';
    if (!this.router.isActive(RoutesApp.HOME, true))
      this.router.navigate([RoutesApp.HOME]);
  }

  public redirectRoute( route: string, queryParams?: any): void {
    if (!route) return;
    if(this.activeTab == this.routes.BIBLE){
      this.loadingOverlayService.showLoadingOverlay();
      setTimeout(() => {
        this.activeTab = route;
        this.closeOption();
        this.router.navigate([route]);
        this.loadingOverlayService.hideLoadingOverlay();
      }, 3000);
    }else{
      this.activeTab = route;
      this.closeOption();
      if(route.search('search-v2')){
        this.router.navigate([route], {queryParams});
      }
      else{
        this.router.navigate([route]);
      }
    }
  }

  public redirectRouteWithParams({ route, queryParams }): void {
    let routeWP = route;
    let queryParamsWP = queryParams;
    this.redirectRoute(routeWP, queryParamsWP);
  }

  public openOption(type: string): void {
    if (type === this.actionActive) this.toggleOpenLink();
    else this.resetOption(type);
    if (this.isActionActive(typeOptionHeader.SEARCH)) this.searchToggle();
  }

  public toggleOpenLink(): void {
    this.openLinkOption = !this.openLinkOption;
  }

  public resetOption(type: string): void {
    this.closeOption();
    this.actionActive = this.actions[type];
    this.openLinkOption = true;
  }

  public handleOption(event: string): void {
    if (!event) this.closeOption();
    else if (event === ActionHeader.LOGOUT) this.logout();
    else if (event === ActionHeader.CONTACT) this.toggle(true);
    else if (event === ActionHeader.WHO) this.toggle(false);
    else if (event === ActionHeader.LOGIN) this.showLoginMessage('enjoy-content');
    else if (event === ActionHeader.DONATE) this.donate();
  }

  public closeOption(): void {
    this.openLinkOption = false;
    this.advanceSearchService.isOpenSearch = false;
    this.actionActive = '';
    this.openWhoAre = false;
    this.openContact = false;
  }

  public logout(): void {
    this.userService.logout();
    this.catalogV2Service.deleteCatalogMyList();
    this.catalogState.clearCatalog();
    this.logoutService.change(true);
    this.closeOption();
    this.subscriptionFromLanding = false;
    this.previewURL = '';
    this.activeTab = '';
    this.isFromAuth = true;
  }

  public searchToggle(): void {
    this.advanceSearchService.searchToggle();
  }

  public toggle(contact: boolean): void {
    this.openLinkOption = false;
    this.advanceSearchService.isOpenSearch = false;
    this.actionActive = '';
    if (contact) this.openContact = !this.openContact;
    else this.openWhoAre = !this.openWhoAre;
  }

  public changeCountry(country: CountryTimeZone): void {
    this.countrySelected = country;
  }

  public isMoreTabActive(tab: string): boolean {
    return this.moreTabActiveTitles.some(x => x === tab) && !this.openLinkOption;
  }

  public setActiveTabOnReload(): void {
    // URLS más largas me interesa solo la primera parte para el ActiveTab
    // let longUrlRouteSplit = '';
    // if(this.currentRoute.split('?')[0].length > 1)
    //   longUrlRouteSplit = '/' + this.currentRoute.split('?')[0].split('/')[1];
    // else longUrlRouteSplit = '';
    // this.activeTab = this.utilsServ.isStringAndNotEmpty(longUrlRouteSplit) ? longUrlRouteSplit : this.currentRoute;
  }
}
