import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrayRequestModalComponent } from '../modals/pray-request-modal/pray-request-modal.component';
import { LocalStorage } from 'ngx-webstorage';
import { IUser } from '@app/core/models/interfaces/i-user.interface';
import { LoginModalComponent } from '@app/layout/components/login-modal/login-modal.component';

@Component({
  selector: 'app-btn-pray-request',
  templateUrl: './btn-pray-request.component.html',
  styleUrls: ['./btn-pray-request.component.scss']
})
export class BtnPrayRequestComponent{

  private hiddenRoutes: string[] = ['auth', 'mySubscription', 'myPaymentMethods','mobile'];
  @LocalStorage("user") private user: IUser;

  constructor(
    private router: Router,
    private modalService: NgbModal
  ) {}

  get currentRoute(): string {
    return this.router.url.split('?')[0];
  }

  get isHiddenbtn(): boolean {
    return this.currentRoute.split('/').some((el) => this.hiddenRoutes.includes(el));
  }


  openModalPray(): void {
    if (!this.user) {
      this.modalService.open(LoginModalComponent, {
        windowClass: "dark-modal modal-outlogin",
        backdropClass: "light-black-backdrop",
      });
    } else {
      const modalRef = this.modalService.open(PrayRequestModalComponent, {
        windowClass: 'modal dark-modal modal-pray-request2',
        backdropClass: 'light-black-backdrop'
      });
      modalRef.componentInstance.user = this.user;
    }
  }

}
