import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DateSeparatePipe } from './date-separate.pipe';
import { MapperDropdownListPipe } from './mapper-dropdown-list.pipe';
import { ResultLoginPipe } from './results-login.pipe';
import { NgForSortPipe } from './ng-for-sort.pipe';
import { CreditCardSeparatorPipe } from './credit-card-separator.pipe';
import { DateExpiredSeparatorPipe } from './date-expired-separator.pipe';
import { GetNamePurchaseByContentTypePipe } from './get-name-purchase-by-content-type.pipe';
import { GetStatusIconTransactionsPipe } from './get-status-icon-transactions.pipe';
import { IsExpiredContentPipe } from './is-expired-content.pipe';
import { DaysLeftTextPipe } from './days-left-text.pipe';
import { GetImageMethodDonationsPipe } from './get-image-method-donations.pipe';
import { GetTextStatusTransactionPipe } from './get-text-status-transaction.pipe';
import { DateLocalFormatPipe } from './date-local-format.pipe';
import { GetImageUrlByTypePipe } from './get-image-url-by-type.pipe';
import { SafePipe } from './safe.pipe';
import { HeroImageHomePipe } from './hero-image-home.pipe';
import { SearchElementsPipe } from './search-element.pipe';
import { CustomDatePipe } from './custom-date.pipe';
import { GetClassContentNamePipe } from './get-class-content-name.pipe';
import { TimeFormatPipe } from './time-format.pipe';

@NgModule({
  declarations: [
    DateSeparatePipe,
    MapperDropdownListPipe,
    ResultLoginPipe,
    NgForSortPipe,
    CreditCardSeparatorPipe,
    DateExpiredSeparatorPipe,
    GetNamePurchaseByContentTypePipe,
    GetStatusIconTransactionsPipe,
    IsExpiredContentPipe,
    DaysLeftTextPipe,
    GetImageMethodDonationsPipe,
    GetTextStatusTransactionPipe,
    DateLocalFormatPipe,
    GetImageUrlByTypePipe,
    SafePipe,
    HeroImageHomePipe,
    SearchElementsPipe,
    CustomDatePipe,
    GetClassContentNamePipe,
    TimeFormatPipe
  ],
  imports: [CommonModule],
  exports: [
    DateSeparatePipe,
    MapperDropdownListPipe,
    ResultLoginPipe,
    NgForSortPipe,
    CreditCardSeparatorPipe,
    DateExpiredSeparatorPipe,
    GetNamePurchaseByContentTypePipe,
    GetStatusIconTransactionsPipe,
    IsExpiredContentPipe,
    DaysLeftTextPipe,
    GetImageMethodDonationsPipe,
    GetTextStatusTransactionPipe,
    DateLocalFormatPipe,
    GetImageUrlByTypePipe,
    SafePipe,
    HeroImageHomePipe,
    SearchElementsPipe,
    CustomDatePipe,
    GetClassContentNamePipe,
    TimeFormatPipe
  ],
})
export class PipesV2Module {}
