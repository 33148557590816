<div class="subscribe-comp">
  <div class="subscribe-container">
    <div class="main-info">
      <a [routerLink]="['/']">
        <img id="enlacelogo" src="assets/img/landing-subscribe/images/logo.png" alt="Logo Enlace+">
      </a>

      <div class="info">
        <h2>MISIONERO DIGITAL</h2>
        <p>Con tu suscripción te conviertes en un <b>MISIONERO DIGITAL.</b></p>
        <p>Tu aporte mensual de $4.99 o más, permite que miles de personas puedan acceder gratuitamente al contenido que transmite el mensaje de salvación.</p>
        <h4 class="pt12"><b>Este plan te permite:</b></h4>
        <h4>Acceder a todo el contenido en diferentes formatos, adquirir contenidos gratuitos o con un valor menor al plan básico. No verás ningún anuncio al reproducir vídeos y además podrás publicar un anuncio de manera gratuita, en nuestra Comunidad de Productos y Servicios. También te daremos adelantos e información exclusiva del avance del proyecto. ¡Hazte ya un Misionero Digital!</h4>
        <div class="price">
          <p>Desde</p>
          <span>US${{ subscriptionPrice }}</span>
        </div>

        <button (click)="subscribe(true)" class="btn">SUSCRIBIRME</button>
      </div>
    </div>
    
    <div class="row footer">
      <div class="col-lg-6 col-sm-6 col-xs-12 visible-md visible-lg visible-sm hidden-xs">
        <h2 class= "verse">{{ verse }}</h2>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-2 col-xs-6">
        <a><img class="scale-85" alt="Enlace.plus" src="assets/img/landing-subscribe/images/link.png" /></a>
      </div>
      <div (click)="goToStore()" class="col-lg-4 col-md-3 col-sm-4 col-xs-6 center-wk">
        <a><img class="visible-md visible-lg hidden-sm hidden-xs high store" alt="Tiendas" src="assets/img/landing-subscribe/images/tiendas.png" /></a>
        <a><img class="hidden-md hidden-lg visible-sm visible-xs float-right" alt="Tiendas Medium" src="assets/img/landing-subscribe/images/Tiendas-resp.png" /></a>
      </div>
      <div class="col-lg-6 col-sm-6 col-xs-12 hidden-md hidden-lg hidden-sm visible-xs">
        <h2 class="adjust-verse">{{ verse }}</h2>
      </div>
    </div>
  </div>
  
  <div class="col-xs-12 white-banner">
    <h1>Accede a todo el contenido en este momento</h1>
  </div>

  <div>
    <img alt="Catalogo" src="assets/img/landing-subscribe/images/catalogo.png" width="100%" />
  </div>
  
  <div class="digital-content flex">
    <img alt="Play" class="scale-75" src="assets/img/landing-subscribe/images/Icono movie-responsive.svg" width="10%" />
    <h3 class="ml-2 center">Este contenido y más, puedes verlo gracias a los <b>MISIONEROS DIGITALES</b> que han donado de manera voluntaria.</h3>
  </div>

  <img alt="Tablet" src="assets/img/landing-subscribe/images/tablet.png" class="scale-xs" width="100%" />
</div>
