import { Component, OnInit } from '@angular/core';;
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SettingService } from './api-client/v2/setting.service';
import { AdvanceSearchService } from './layout/components/search-advanced/services/advance-search.service';
import { FeatureFlagV3Service } from './shared/services/feature-flags-v3.service';
import { NavigationService } from '@utils/navigation.service';
import { LocalStorage } from 'ngx-webstorage';
import { IUser } from './core/models/interfaces/i-user.interface';
import { environment as ENV } from '@environment/environment';
import { UserStateService } from './shared/services/user-state.service';
import { GtmService } from './shared/services/lib/gtm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @LocalStorage('user')
  private user: IUser;
  isPray = true;
  constructor(
    private router: Router,
    private settingsService: SettingService,
    private featureFlagsService: FeatureFlagV3Service,
    private advanceSearchService: AdvanceSearchService,
    private route: ActivatedRoute,
    public navigation: NavigationService,
    private userService: UserStateService,
    private gtmService: GtmService) {
    this.advanceSearchService.previousUrl = this.router.url;
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isPray = !event.url.includes('watch');
        const url = event.urlAfterRedirects;
        const userId = this.user?.id ?? null;
        this.advanceSearchService.previousUrl = event.url;
        // this.navigation.history.push(event.urlAfterRedirects);
        if (!this.shouldFilterURL(url)) {
          this.gtmService.logPageView(url, userId, ENV.name);
        }
        window.scrollTo(0, 0)
      }
    });
    this.settingsService.listSettings();
  }

  private shouldFilterURL(url: string): boolean {
    const filterKeywords = ['/mobile'];
    const queryParams = this.route.snapshot.queryParams;

    if (filterKeywords.some(keyword => window.location.href.includes(keyword))) {
      return true;
    }

    if (queryParams.fromMobile) {
      return true;
    }

    return false;
  }

  get isOlder14(): boolean {
    return this.userService.userIsOlderThan13();
  }

  get hasFeaturesLoaded(): boolean {
    return this.featureFlagsService.areThereFeatures;
  }

  get isComunityEnabled(): boolean{
    return this.featureFlagsService.checkFeatureOn('WebEnableCommunity');
  }


}
