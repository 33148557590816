 <div class="cont-base {{additionalClass}}" [class.error]="isError" [class.touched]="isTouched" (click)="toggleShowOptions()"
  (clickOutside)="clickOutSide()">
  <div class="select-filter">
    <div class="select-default">
      <span class="title {{additionalClassTitle}}" [class.chosen-option]="isChosenOption">{{ title | cuttext: cutTextLength}}</span>
      <i class="iconlace iconlace-chevron-down {{additionalClassIcon}}" [class.error]="isError"></i>
      <i class="iconlace iconlace-cross {{additionalClassIcon}}" *ngIf="enableClearOption && isChosenOption" (click)="clear()"></i>
    </div>
    <div class="select-wrapper" *ngIf="isOpen">
      <div class="select-option">
        <ng-container *ngFor="let item of optionList">
          <div class="select-item" [ngClass]="{'active': item.value == currentValue?.value}" (click)="sendOption(item); $event.stopPropagation()">
            <span>{{item.name.toString() | translate }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
