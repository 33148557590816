import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { RoutesApp } from '@enums/type-routes-app.enum';
import { LocalStorageService } from 'ngx-webstorage';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private readonly historyKey = 'navigationHistory';
  public history: string[] = [];
  public lastUrl: string = '';
  private currentUrl: string = '';

  constructor(
    private router: Router,
    private localStorage: LocalStorageService
  ){
    this.currentUrl = this.router.url;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (this.currentUrl !== event.urlAfterRedirects) {
        this.lastUrl = this.currentUrl;
        this.currentUrl = event.urlAfterRedirects;
        this.saveLastUrl(this.lastUrl);
      }
    });
  }

  public goBack(contentType?: string, contentId?: string): void {
    if (this.lastUrl) {
      const isVideoUrl = this.lastUrl.includes('/watch/');
      if ((this.lastUrl === RoutesApp.HOME && contentType && contentId) || isVideoUrl) {
        this.router.navigateByUrl(`/${contentType}/${contentId}`);
      } else {
        this.router.navigateByUrl(this.lastUrl);
      }
    } else {
      this.router.navigateByUrl(RoutesApp.HOME);
    }
  }

  private saveLastUrl(url: string): void {
    this.lastUrl = url;
    this.localStorage.store(this.historyKey, url);
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 1];
  }

}
