<div *ngIf="searchResult" class="row mb-25"
  infiniteScroll [infiniteScrollDistance]="6" (scrolled)="onScroll(searchResult.typeFilter)">
  <!-- Header content -->
  <div class="col-lg-12">
    <div class="header-content">
      <div class="type-content">
        <i [class]="'iconlace iconlace-' + searchResult.typeFilter"></i>
        <span class="type-content-title">
          {{ searchResult?.title }}
          <small>({{ totalRows }})</small>
        </span>
      </div>
      <div class="more-content" *ngIf="canSeeMore" (click)="changeFilter(searchResult.typeFilter)">
        <span>Ver más</span>
        <i class="iconlace iconlace-chevron-next"></i>
      </div>
      <div class="close-content" *ngIf="canCloseResultsSearched" (click)="changeFilter(contentTypeSearch.ALL)">
        <i class="iconlace iconlace-cross"></i>
      </div>
    </div>
  </div>
  <!-- List content -->
  <div class="col-lg-12">
    <div class="list-content"
      [class.isMobile]="isMobile"
      [class.books]="
        searchResult.typeFilter === contentTypeSearch.AUDIOBOOK ||
        searchResult.typeFilter === contentTypeSearch.EBOOK">
      <ng-container *ngIf="!canCloseResultsSearched; else resultsByContent">
        <ng-container *ngFor="let item of searchResult?.list; let i = index; let last = last; let odd = odd; let even = even; trackBy: trackByItems">
          <div class="column" *ngIf="i < elementRowByType(searchResult.typeFilter)" [class.overflow-hidden]="searchResult.typeFilter !== contentTypeSearch.NARRATED_BIBLE && searchResult.typeFilter !== contentTypeSearch.VIDEO">
            <ng-container *ngTemplateOutlet="contentItem; context: { item: item, last: last, odd: odd, even: even }"></ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #resultsByContent>
        <ng-container *ngFor="let item of searchResult?.list; let i = index; let last = last; let odd = odd; let even = even; trackBy: trackByItems">
          <div class="column" [class.overflow-hidden]="searchResult.typeFilter !== contentTypeSearch.NARRATED_BIBLE && searchResult.typeFilter !== contentTypeSearch.VIDEO">
            <ng-container *ngTemplateOutlet="contentItem; context: { item: item, last: last, odd: odd, even: even }"></ng-container>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>

  <div class="col-lg-12 button-go-top">
    <button id="goTop" class="btn go-top"
      *ngIf="canCloseResultsSearched && !canPaging" (click)="goTop()">
      <i class="iconlace iconlace-arrow-up"></i> 
    </button>
  </div>
</div>

<ng-template #contentItem let-item="item" let-last="last" let-even="even" let-odd="odd">
  <div
    class="content-item"
    [class.not-books]="
      searchResult.typeFilter !== contentTypeSearch.AUDIOBOOK &&
      searchResult.typeFilter !== contentTypeSearch.EBOOK"
    on-mouseover="currentVideo = item.id"
    on-mouseout="currentVideo = -1">
    <!-- CALL TO ACTION LATERAL SUPERIOR DERECHO -->
    <ng-container
      *ngIf="searchResult.typeFilter === contentTypeSearch.NARRATED_BIBLE">
      <div class="content-transcription">
        <span
          *ngIf="searchResult.typeFilter === contentTypeSearch.NARRATED_BIBLE"
          class="bible"
          (click)="showIndexer(item)"
          ngbTooltip="Índice"
          placement="top"
          container="body">
          <i class="iconlace iconlace-narrated-bible"></i>
        </span>
      </div>
    </ng-container>

    <ng-container
      *ngIf="searchResult.typeFilter === contentTypeSearch.VIDEO && (!isPPV(item) || item.acquired)">
      <div class="content-transcription">
        <span
          *ngIf="searchResult.typeFilter === contentTypeSearch.VIDEO"
          class="video"
          (click)="showTranscription(item)"
          ngbTooltip="Transcripciones automatizadas"
          placement="top"
          container="body">
          <i class="iconlace iconlace-transcription"></i>
        </span>
      </div>
    </ng-container>

    <!-- POP UP TRANSCRIPTIONS -->
    <div
      [id]="item.id"
      [ngClass]="{
        'transcriptions-main-search': item.type != contentTypeSearch.NARRATED_BIBLE,
        'indexer-main-search': item.type == contentTypeSearch.NARRATED_BIBLE}">
      <app-transcriptions
        *ngIf="item.type != contentTypeSearch.NARRATED_BIBLE && item.isShowTranscriptions"
        [ngClass]="{ 'last-item': last }"
        [isLast]="last"
        [isEven]="even"
        [isOdd]="odd"
        [fromSearch]="true"
        [search]="searchResult.search"
        [video]="item"
        [index]="item.id"
        (close)="item.isShowTranscriptions = false"
        (clickTranscription)="goToVideoSecond($event, item.id, item.type)">
      </app-transcriptions>
      <app-video-indexer
        *ngIf="item.isShowIndexer"
        [ngClass]="{ 'last-item': last }"
        [isLast]="last"
        [isEven]="even"
        [isOdd]="odd"
        [fromSearch]="true"
        [search]="searchResult.search"
        [video]="item"
        [index]="item.id"
        (clickIndex)="goToVideoSecond($event, item.id, item.type)"
        (close)="item.isShowIndexer = false">
      </app-video-indexer>
    </div>

    <div
      [ngClass]="{
        'embed-responsive embed-responsive-16by9':
          item.type === contentTypeSearch.VIDEO ||
          item.type === contentTypeSearch.PROGRAM ||
          item.type === contentTypeSearch.NARRATED_BIBLE}">
      <img
        *ngIf="
          searchResult.typeFilter !== contentTypeSearch.AUDIOBOOK &&
          searchResult.typeFilter !== contentTypeSearch.EBOOK"
        class="img-responsive"
        [src]="item.image || 'assets/img/no-image.png'"
        src-fallback="assets/img/no-image.png"/>
    </div>

    <!-- SECTION VIDEO - PROGRAM - PODCAST - ARTICLES - BIBLE NARRATED -->
    <div class="content-body"
      *ngIf="item.type != contentTypeSearch.AUDIOBOOK && item.type != contentTypeSearch.EBOOK"
      (click)="navigate(item)">
      <img
        class="content-play"
        *ngIf="
          searchResult.typeFilter === contentTypeSearch.VIDEO ||
          searchResult.typeFilter === contentTypeSearch.NARRATED_BIBLE"
        src="assets/img/icons/play_thumb.svg"/>
      <app-ppv-label-indicator [content]="item"></app-ppv-label-indicator>
      <app-ppv-remaining-indicator [content]="item"></app-ppv-remaining-indicator>
      <div class="result-info" [ngClass]="item.type">
        <img
          class="result-item-image"
          [ngClass]="item.type"
          [src]="item.hosts | ImageHost"/>
        <p class="result-item-title" [ngClass]="item.type">
          <strong>{{ item?.title }}</strong>
        </p>
        <p class="wrap" [ngClass]="item.type">{{ item | SubtitleHost }}</p>
        <p class="wrap" [ngClass]="item.type">
          {{ item.duration | timeHumanFormat }}
        </p>
      </div>
    </div>

    <!-- SECTION EBOOKS - AUDIOBOOK -->
    <ng-container
      *ngIf="item.type === contentTypeSearch.AUDIOBOOK || item.type === contentTypeSearch.EBOOK">
      <a class="slider-info">
        <div class="slider-info-detail article">
          <div class="slider-clickable" (click)="show(item)"></div>
          <app-slider-audiobook-item
            *ngIf="item.type == contentTypeSearch.AUDIOBOOK"
            class="section-content-item"
            [audiobook]="mapSearchItemToBooks(item)"
            (click)="show(item)">
          </app-slider-audiobook-item>
          <app-slider-book-item
            *ngIf="item.type == contentTypeSearch.EBOOK"
            class="section-content-item"
            [book]="mapSearchItemToBooks(item)"
            (click)="show(item)">
          </app-slider-book-item>
        </div>
      </a>
    </ng-container>
  </div>
</ng-template>
