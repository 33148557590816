<div (click)="navigate()" style="text-align: left; margin-left: 12px;">
  <ng-template
    [ngIf]="isBook(type)"
    [ngIfElse]="notBookItem">
    <div style="cursor: pointer; padding: .75em; float: left">
      <app-slider-audiobook-item
        *ngIf="type === 'audiobook'"
        [mySocial]="true"
        [audiobook]="content"></app-slider-audiobook-item>
      <app-slider-book-item
        *ngIf="type === 'ebook'"
        [mySocial]="true"
        [book]="content"></app-slider-book-item>
    </div>
  </ng-template>
  <ng-template #notBookItem>
    <div [ngClass]="{'slider-item-podcast' : type =='podcast' || type == 'podcastitem'}"
         [ngClass]="isWebview == false ? 'result-item ' + this.type : 'result-item-webview ' + this.type"
         class="col-md-3 col-sm-4 col-xs-12 col-ls-4"
         style="cursor: pointer; width: 20%; padding: .75em;">
      <div>
        <img (click)="navigate()" *ngIf="type === 'video'" class="play" src="assets/img/icons/play_thumb.svg">
        <a [ngClass]="{'slider-info' : type =='podcast' || type == 'podcastitem',
      'embed-responsive embed-responsive-16by9': type !== 'podcast' && type !== 'podcastitem'}">
          <img [class.width-full]="!isFollow" [src]="imageUrl || '/assets/img/no-image.png'" class="img-responsive"
               src-fallback="/assets/img/no-image.png"/>
            <app-ppv-label-indicator [content]="content"></app-ppv-label-indicator>
            <app-ppv-remaining-indicator [content]="content"></app-ppv-remaining-indicator>
          <div class="result-info" [class.topic]="isTopic" *ngIf="type !== 'article'; else resultInfoArticle">
            <p class="result-item-title" container="body" ngbTooltip="{{title}}" placement="top">
              <strong>{{title | cuttext: type == 'podcast' || type == 'podcastitem' ? 26 : 30}}</strong></p>
            <p class="wrap">{{subtitle}}</p>
            <a (click)="MyUnFollow(contentId)" class="iconlace iconlace-cross icon-unfollow-{{type}}" container="body"
               [ngbTooltip]="textTooltip" placement="top"></a>
          </div>
          <ng-template #resultInfoArticle>
            <div class="result-info-article">
              <div class="result-info-body">
                <div class="result-info-img">
                  <img
                    [src]="content.hostsList && content.hostsList.length > 0 && content.hostsList[0].image != null && content.hostsList[0].image != '' ? content.hostsList[0].image : 'assets/img/icons/user.svg'"/>
                </div>
                <div class="result-info-title">{{content.title}}</div>
                <a *ngIf="isFollow" (click)="MyUnFollow(contentId)" class="iconlace iconlace-cross icon-unfollow-{{type}} article" container="body"
               [ngbTooltip]="textTooltip" placement="top"></a>
              </div>
            </div>
          </ng-template>
          
        </a>
      </div>
    </div>
  </ng-template>
</div>
