import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageFilter'
})
export class ImageFilterPipe implements PipeTransform {

  transform(item: any): string {
    if (!!item.image) return item.image;
    else if (!!item.avatarUrl) return item.avatarUrl;
    else if (!!item.code) return this.getImageFromCode(item);
    return '/assets/img/avatar.png';
  }

  getImageFromCode(item): string {
    let imgCode = item.code.toLowerCase();
    if (item.code === 'es-ES') {
      imgCode = 'esp';
    } else if (item.code === 'en-US') {
      imgCode = 'usa';
    } else if (item.code === 'UNK') {
      imgCode = 'glo';
    }
    return '/assets/img/flags/' + imgCode + '.png';
  }

}
