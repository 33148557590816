<div class="item-list row"
    infiniteScroll
    [infiniteScrollDistance]="2"
    (scrolled)="onScroll()"
    style="margin-bottom: 25px"
    *ngIf="showResults">

  <div class="col-sm-10 col-md-12"
       *ngIf="searchResult.isShowAllElements || advanceSearchService.typeSelected.value !== 'all' ">
    <div class="header-search">
      <div class="row filter-button">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <!-- <span>{{'search-results' | language }}</span> -->
          <h3>{{totalRows}} {{searchResult?.title}} para <b>{{searchResult?.pageInfo.search}}</b></h3>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12" *ngIf="advanceSearchService.typeSelected.value === 'all'">
    <div class="item-list-title">
      <i [class]="'iconlace iconlace-' + searchResult.typeFilter"></i>
      <h4 class="item-list-type">{{searchResult?.title}}
        <small>({{totalRows}})</small>
      </h4>
    </div>
    <span style="cursor:pointer;" class="item-list-more text-right" (click)="changeTypeFilter(searchResult.typeFilter)"
          *ngIf="searchResult?.totalItems > elementsRow"><i class="iconlace iconlace-plus-mini"></i> Ver más</span>
  </div>
  <!-- <div class="col-md-12 dark-forms" *ngIf="searchResult?.catalogs && searchResult.isShowAllElements && searchResult?.catalogs.length > 0"> -->
  <div *ngIf="advanceSearchService.typeSelected.value !== 'all'" class="col-md-12 dark-forms">
    <span style="cursor:pointer;" class="item-list-more text-right" (click)="changeTypeFilter('all')"><i
      class="iconlace iconlace-cross"></i></span>
  </div>
  <ul class="items">
    <li class="{{searchResult.typeFilter}}-items-childs" [ngClass]="{'all-result': searchResult.isShowAllElements}"
         *ngFor="let video of searchResult?.list; let i=index;let last = last">
      <div style="cursor: pointer" *ngIf="(i<elementsRow)"[ngClass]="{'slider-item-podcast' : video.type =='podcast', 'slider-item-audiobook' : video.type =='audiobook', 'slider-item-ebook' : video.type =='ebook',
      'result-item': (video.type !== 'podcast' && video.type !== 'audiobook' && video.type !== 'ebook')}" class="col-md-3 col-sm-4 col-xs-12 col-ls-4 slider-item-ebook"
           on-mouseover="currentVideo = video.id"
           on-mouseout="currentVideo = -1" [ngClass]="{'': isShowFilter, 'without-filters': !isShowFilter }">
           <div [ngClass]="{'transcriptions-main-search' : video.type != 'narrated-bible', 'indexer-main-search' : video.type == 'narrated-bible'}" id="{{video.id}}">
              <app-transcriptions *ngIf="video.type != 'narrated-bible' && video.isShowTranscriptions" (clickTranscription)="goToVideoSecond($event, video.id, video.type)"
                [ngClass]="{'last-item': last}" [fromSearch]="true" [search]="searchResult.search" [video]="video"
                (close)="video.isShowTranscriptions = false" [index]="video.id"></app-transcriptions>
              <app-video-indexer *ngIf="video.isShowIndexer" (clickIndex)="goToVideoSecond($event, video.id, video.type)"
                [ngClass]="{'last-item': last}" [fromSearch]="true" [search]="searchResult.search" [video]="video"
                (close)="video.isShowIndexer = false" [index]="video.id"></app-video-indexer>
           </div>
        <div *ngIf="(video.type != 'audiobook' && video.type != 'ebook') && (!video.isPPV || video.acquired)" class="transcriptions-content">
          <span class="transcriptions-toggle" (click)="showTranscription(video)"
                *ngIf="searchResult.typeFilter === 'video'" ngbTooltip="Transcripciones automatizadas" placement="top" container="body">
              <i class="iconlace iconlace-transcription"></i>
          </span>
        </div>
        <div *ngIf="(video.type == 'narrated-bible')" class="indexer-content">
          <div class="narrated-bible-box">
          <span class="indexer-toggle narrated-bible-icon" (click)="showIndexer(video)"
                *ngIf="searchResult.typeFilter === 'narrated-bible'" ngbTooltip="Índice" placement="top" container="body">
              <i class="iconlace iconlace-narrated-bible"></i>
          </span>
          </div>
        </div>
        <div class="search-item-content" *ngIf="(video.type != 'audiobook' && video.type != 'ebook')" (click)="navigate(video)">
          <img class="play" *ngIf="searchResult.typeFilter === 'video' || searchResult.typeFilter === 'narrated-bible'" src="assets/img/icons/play_thumb.svg">
          <a class="" [ngClass]="{'slider-info' : (video.type =='podcast' || video.type == 'audiobook'),
          'embed-responsive embed-responsive-16by9': (video.type !== 'podcast')}">
          <app-ppv-label-indicator [content]="video"></app-ppv-label-indicator>
          <app-ppv-remaining-indicator [content]="video"></app-ppv-remaining-indicator>
          <img  class="img-responsive" [src]="video.image || '/assets/img/no-image.png'"
                src-fallback="{{'/assets/img/no-image.png'}}"/>
            <div class="result-info" [ngClass]="video.type">
              <img class="result-item-image" [ngClass]="video.type" [src]="getHostImage(video.hosts)" />
              <p class="result-item-title" [ngClass]="video.type"><strong>{{video?.title}}</strong></p>
              <p class="wrap" [ngClass]="video.type">{{subtitle(video)}}</p>
              <p class="wrap" [ngClass]="video.type">{{video.duration | timeHumanFormat}}</p>
            </div>
          </a>
        </div>
        <!--------------------------     Audiobook  ----------------------->
        <div *ngIf="video.type === 'audiobook'">
          <a class="slider-info audiobook">
            <div class="slider-info-detail audiobook"
                 [ngClass]="{'article': video.type === 'audiobook'}">
              <div class="slider-clickable" (click)="show(video);"></div>
              <app-slider-audiobook-item class="section-content-item" *ngIf="video.type == 'audiobook'"
                                     [audiobook]="mapSearchItemToAudioBook(video)"
                                     (click)="show(video)">
              </app-slider-audiobook-item>
            </div>
          </a>
        </div>
        <!--------------------------  End audiobook  ----------------------->
        <!--------------------------     Book  ----------------------->
        <div *ngIf="video.type === 'ebook'">
          <a class="slider-info book">
            <div class="slider-info-detail book"
                 [ngClass]="{'article': video.type === 'ebook'}">
              <div class="slider-clickable" (click)="show(video);"></div>
              <app-slider-book-item class="section-content-item" *ngIf="video.type == 'ebook'"
                                [book]="mapSearchItemToEBook(video)"
                                (click)="show(video)">
              </app-slider-book-item>
            </div>
          </a>
        </div>
        <!--------------------------     End book  ----------------------->
      </div>
    </li>
  </ul>
</div>
<div class="row" *ngIf="advanceSearchService && advanceSearchService.typeSelected">
  <div class="col-12 text-center">
    <!--button id="paging" class="btn" (click)="paging()"
            *ngIf="advanceSearchService.typeSelected.value !== 'all' && elementsRow < totalRows">Cargar más
    </button-->
    <button id="goTop" class="btn go-top"
            *ngIf="advanceSearchService.typeSelected.value !== 'all' && hasScroll" (click)="goTop()">
      <i class="iconlace iconlace-arrow-up"></i>
    </button>
  </div>
</div>
