import { Injectable } from "@angular/core";
import { WindowReferenceService } from "./window-reference.service";
import {
  LogHomeCatalog,
  LogMarketing,
  LoginTrack,
  PromotionAlertTrack,
  FieldTrack,
  SubscriptionTrack,
  PpvTrack,
  SearchTrack,
  SearchResult,
  SearchInit
} from "@app/core/models/interfaces/promotion-alert-track";

@Injectable({
  providedIn: "root",
})
export class GtmService {

  private window;

  constructor(private _windowRef: WindowReferenceService) {
    this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service
  }

  private pingHome(obj) {
    if (obj) this.window.dataLayer.push(obj);
  }

  //list of all our dataLayer methods

  logPageView(url: string, userId: string, env: string, action: string = '') {
    const hit = {
      event: 'userData',
      pageUrl: url,
      userId: userId,
      env: env,
      ...(action && { action })
    };
    this.pingHome(hit);
  }

  logEvent(event, category, action, label) {
    const hit = {
      event: event,
      category: category,
      action: action,
      label: label,
    };
    this.pingHome(hit);
  }

  logCustomDimensionTest(value) {
    const hit = {
      event: "custom-dimension",
      value: value,
    };
    this.pingHome(hit);
  }

  logPromotionAlert(PromotionAlertTrack: PromotionAlertTrack) {
    this.pingHome(PromotionAlertTrack);
  }

  loglogin(loginTrack: LoginTrack) {
    this.pingHome(loginTrack);
  }

  logHomeCatalogs(logHomeCatalog: LogHomeCatalog) {
    this.pingHome(logHomeCatalog);
  }

  logMarketing(logMarketing: LogMarketing){
    this.pingHome(logMarketing);
  }

  logFieldTrack(fieldTrack: FieldTrack){
    this.pingHome(fieldTrack);
  }

  logSubscriptionTrack(subscriptionTrack: SubscriptionTrack){
    this.pingHome(subscriptionTrack);
  }

  logPaymentPPV(PpvTrack: PpvTrack) {
    this.pingHome(PpvTrack);
  }

  logSearchResult(searchResult: SearchResult) {
    this.pingHome(searchResult);
  }

  logSearchInit(searchInit: SearchInit) {
    this.pingHome(searchInit);
  }

  logSearch(searchTrack: SearchTrack) {
    this.pingHome(searchTrack);
  }
}
