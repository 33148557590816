<div class="taxes-modal">
  <div class="taxes-header">
    <button type="button" class="close-taxes" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="taxes-content">
    <div class="info-side-left">
      <div class="content-image">
        <img [src]="paymentContent?.imageContent || '/assets/img/no-image.png'">
      </div>
    </div>
    <div class="info-side-right">
      <div class="info-main-title">{{ paymentContent?.title }}</div>
      <div class="info-main-subtitle">{{title}}</div>
      <div class="info-content">
        <div class="info-item">
          <div class="info-title">Subtotal:</div>
          <div class="info-subtitle">${{previewTax.totalAmount | number:'1.2-2'}}</div>
        </div>
        <div class="info-item">
          <div class="info-title">Impuesto de ventas:</div>
          <div class="info-subtitle">${{previewTax?.totalTaxCalculated | number:'1.2-2'}}</div>
        </div>
        <div class="info-item border">
          <div class="info-title">Total:</div>
          <div class="info-subtitle">${{(previewTax.totalAmount + previewTax.totalTaxCalculated) | number:'1.2-2' }}</div>
        </div>
        <div class="info-help">(Dólares estadounidenses)</div>
      </div>
    </div>
  </div>
  <div class="copy-paypal" *ngIf="gatewayPaypal">Serás redirigido a la página de Paypal para realizar el proceso de pago.
  </div>
  <button type="button" class="btn btn-primary btn-pay" (click)="pay()">
    {{ gatewayPaypal ? 'PAGAR CON' : 'PAGAR' }}
    <img *ngIf="gatewayPaypal" width="70" src="/assets/img/payment/payment-paypal.png">
  </button>
</div>
