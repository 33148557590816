import { ChangeDetectionStrategy, EventEmitter, Component, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserStateService } from '@services/user-state.service';
import { BreadcrumbService } from '@utils/breadcrumb.service';
import { MobileService } from '@utils/mobile.service';

import { QueryUrlResolve } from '@interfaces/i-structure-url.interface';
import { BreadCrumb } from '@interfaces/i-breadcrumb.interface';
import { RoutesApp } from '@enums/type-routes-app.enum';

@Component({
  selector: 'app-breadcrumb-v2',
  templateUrl: './breadcrumb-v2.component.html',
  styleUrls: ['./breadcrumb-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbV2Component  {

  public icon = 'isotipo'
  public colorSeparatorDefault = '#FFF';

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private mobileService: MobileService,
    private userService: UserStateService,
  ) { }

  get breadcrumbData$(): Observable<BreadCrumb> {
    return this.breadcrumbService.breadcrumbData$;
  }

  get isMobile$(): Observable<boolean> {
    return this.mobileService.isMobileDevice$;
  }

  public redirectRoute(route: string, queries?: QueryUrlResolve): void {
    if (!route) return;
    if (route === RoutesApp.SETTING)
      this.userService.setIsEditing(false);
    !!queries
      ? this.router.navigate([route], { queryParams: {...queries}})
      : this.router.navigate([route]);
  }

  public redirectRouteExtra(route: string, canclick: boolean): void {
    if (!!route && !!canclick)
      this.router.navigate([route]);
  }

  public redirectHome(): void {
    this.router.navigate([RoutesApp.HOME]);
  }

}
